import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import Window from '@lib/window-size';

// ---------------------------------------------------------

import styles from '../styles.module.scss';
const { input_container, input_label, remove_label } = styles;

// ---------------------------------------------------------

const ReactMultiSelect = (props) => {
  const {
    selectOptions = [],
    placeholder = 'Your input please (min 2 chars)',
    label,
    isMultiSelect = true,
    hideInput,
    selectedItems,
    onChange,
    isLoading
  } = props;

  const fullscreenModal = Window().width <= 720;

  const formatSelection = (options) => {
    if (!options?.length) return [];

    let formattedArray = options.map((option) => {
      if (typeof option === 'object') {
        return {
          label: option.name || option.label || option.location,
          value: option.value || option.agentId || options.location
        };
      } else {
        return {
          label: option,
          value: option
        };
      }
    });
    return formattedArray;
  };

  // ---------------------------------------------------------

  const customFilter = (option, searchTerm) => {
    if (option?.value === 'loading') return false;
    if (option?.value?.location == undefined) return;

    const lowerCasedOption = option.value.location?.toLowerCase();
    const splitArray = lowerCasedOption?.split(' ');

    const locationLabels = (selectedItems || []).map((loc) => loc?.label);

    for (let i = 0; i < splitArray.length; i++) {
      if (
        !locationLabels.includes(option.label) &&
        (lowerCasedOption.startsWith(searchTerm?.toLowerCase()) ||
          splitArray[i].startsWith(searchTerm?.toLowerCase()))
      ) {
        return true;
      }
    }
  };

  const customStyle = {
    control: (base, state) => ({
      ...base,
      '&:hover': {
        borderColor: '0.5px solid #ddd'
      },
      backgroundColor: '#f6f6f6',
      border: state.isFocused ? '1px solid #939598' : '0.5px solid #ddd',
      boxShadow: 'none',
      width: '100%'
    }),
    input: (base) => ({
      ...base,
      fontSize: fullscreenModal ? '16px' : '12px'
    }),
    menu: (base) => ({
      ...base,
      marginTop: '0px',
      maxHeight: '200px'
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: '200px'
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: 'white',
      borderRadius: '20px',
      boxShadow: '0 0 6px 0 rgb(0 0 0 / 10%), 0 0 2px 0 rgb(0 0 0 / 10%)',
      display: 'flex',
      margin: '5px 5px 5px 0'
    }),
    multiValueLabel: (base) => ({
      ...base,
      fontFamily: 'var(--font-family-base) !important',
      fontSize: fullscreenModal ? '16px' : '12px',
      padding: '3px 3px 3px 0px',
      paddingLeft: '15px'
    }),
    multiValueRemove: (base) => ({
      ...base,
      '&:hover': {
        backgroundColor: '#ddd',
        borderBottomRightRadius: '20px',
        borderTopRightRadius: '20px',
        color: 'black',
        cursor: 'pointer'
      }
    }),
    placeholder: (base) => ({
      ...base,
      fontFamily: 'var(--font-family-medium) !important',
      fontSize: fullscreenModal ? '16px' : '12px'
    })
  };

  const MultiValueRemove = (props) => {
    return (
      <components.MultiValueRemove {...props}>
        <span className={remove_label} />
      </components.MultiValueRemove>
    );
  };

  return (
    <div className={input_container}>
      {!hideInput && (
        <label className={input_label}>
          <span>{label}</span>
          <Select
            components={{ MultiValueRemove }}
            defaultValue={formatSelection(selectedItems)}
            value={selectedItems}
            options={formatSelection(selectOptions)}
            isMulti={isMultiSelect}
            name={label}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder={placeholder}
            onChange={onChange}
            filterOption={customFilter}
            styles={customStyle}
            noOptionsMessage={() => (isLoading ? 'Loading...' : 'No results found')}
          />
        </label>
      )}
    </div>
  );
};

// ---------------------------------------------------------

ReactMultiSelect.propTypes = {
  /**
   * Option to view the tags of the selected options under the input field
   */
  existingSelections: PropTypes.any,

  /**
   * Option to view the tags of the selected options under the input field
   */
  hideInput: PropTypes.bool,

  /**
   * Prop to indicate whether the debounce is active causing a loading state
   */
  isLoading: PropTypes.bool,

  /**
   * Prop to indicate whether the dropdown supports a single selection
   * or multiple selections at the same time
   */
  isMultiSelect: PropTypes.bool,

  /**
   * Serves as the label/title above the input field
   */
  label: PropTypes.string,

  /**
   * A callback function to update a list of selection in the parent component
   */
  onChange: PropTypes.func,

  /**
   * Placeholder text for when the input field is empty
   */
  placeholder: PropTypes.string,

  /**
   * Specifies the scroll to position
   */
  scrollToPosition: PropTypes.any,

  /**
   * A list of options for the user to select, to be displayed in the dropdown
   */
  selectOptions: PropTypes.array,

  /**
   * Specifies the displayed items
   */
  selectedItems: PropTypes.array,

  /**
   * Sets the displayed items
   */
  setSelectedItems: PropTypes.func
};

export default ReactMultiSelect;
