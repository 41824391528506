import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// ---------------------------------------------------------

import StepOne from '@components/advanced-search/step-one';
import StepTwo from '@components/advanced-search/step-two';
import Modal from '@components/modal';

import { initialState } from '@src/store/slices/searchFiltersSlice';

// ---------------------------------------------------------

import styles from './styles.module.scss';
const { container, header, outer_container } = styles;

// ---------------------------------------------------------

const { locations, maxPrice, minPrice } = initialState;
const defaultModalFilters = {
  locations,
  maxPrice,
  minPrice,
  propertySubSelectIds: ['1', '2'],
  propertyTypeId: '1'
};

const AdvancedSearch = (props) => {
  let { quicklink, setQuicklink, modalIsOpen, setModalIsOpen, isNewSearch } = props;

  const [currentStep, setCurrentStep] = useState(1);
  const [modalFilters, setModalFilters] = useState(defaultModalFilters);

  const { locations: reduxLocations } = useSelector((state) => state.searchFilters);

  useEffect(() => {
    // start out with an empty location array if new search, otherwise grab
    // the location values from the redux store
    if (!isNewSearch) {
      setModalFilters({ ...modalFilters, locations: reduxLocations });
    }
  }, reduxLocations);

  const onModalClose = () => {
    if (setQuicklink) {
      setQuicklink('');
    }
    setModalIsOpen(false);
    setCurrentStep(1);
  };

  return (
    <Modal
      modalIsOpen={modalIsOpen}
      theme="advanced-search"
      contentLabel="AdvancedSearch"
      onModalClose={onModalClose}
      content={
        <div className={outer_container}>
          <div className={container}>
            <h2 className={header}>
              {quicklink
                ? quicklink
                : window.location.pathname == '/results' && !isNewSearch
                ? 'Add Location(s)'
                : 'Advanced Search'}
            </h2>

            {currentStep === 1 && (
              <StepOne
                quicklink={quicklink}
                setCurrentStep={setCurrentStep}
                currentStep={currentStep}
                isNewSearch={isNewSearch}
                setModalIsOpen={setModalIsOpen}
                modalFilters={modalFilters}
                setModalFilters={setModalFilters}
              ></StepOne>
            )}

            {currentStep === 2 && (
              <StepTwo
                setModalIsOpen={setModalIsOpen}
                setCurrentStep={setCurrentStep}
                modalFilters={modalFilters}
                setModalFilters={setModalFilters}
                defaultModalFilters={defaultModalFilters}
              ></StepTwo>
            )}
          </div>
        </div>
      }
    />
  );
};

AdvancedSearch.propTypes = {
  /**
   * Sets the quicklink that has been clicked to open the modal
   */
  isNewSearch: PropTypes.bool,

  /**
   * Specifies whether the modal is currently opened
   */
  modalIsOpen: PropTypes.bool,

  /**
   * Specifies which quick link button opened the advanced search modal
   */
  quicklink: PropTypes.string,

  /**
   * Sets whether the modal is open or closed
   */
  setModalIsOpen: PropTypes.func,

  /**
   * Sets the quicklink that has been clicked to open the modal
   */
  setQuicklink: PropTypes.func
};

export default AdvancedSearch;
