import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import Search from '@lib/models/search';

// ---------------------------------------------------------

import propertyTypeMap from '@src/data/property-type-map.json';
import { priceOptions } from '@content/results/filters';
import { convertFromReduxParams, pushStateToQuery } from '@lib/query-utils';

// ---------------------------------------------------------

import RadioButtons from '@components/radio-button';
import MultipleSelect from '@components/select/minmax-select';
import Button from '@components/button';

const search = new Search();

// ---------------------------------------------------------

import styles from '../styles.module.scss';
const { subheader, button_container, subheader_container, locations_container, location_label } =
  styles;

// ---------------------------------------------------------

const StepTwo = (props) => {
  let { setCurrentStep, setModalIsOpen, modalFilters, setModalFilters, defaultModalFilters } =
    props;

  const router = useRouter();
  const [count, setCount] = useState(0);
  const reduxParams = useSelector((state) => state.searchFilters);

  const fetchCount = async () => {
    const { paramsForGraphQL } = await convertFromReduxParams(modalFilters);
    const data = await search.perform({
      params: { ...paramsForGraphQL, status: ['Active', 'Pending'] },
      queryType: 'advancedSearch'
    });
    setCount(data?.listingSearch?.totalCount ?? 0);
  };

  useEffect(() => {
    fetchCount();
  }, [reduxParams, modalFilters]);

  const handlePropertySubChange = (newSelections) => {
    setModalFilters({ ...modalFilters, propertySubSelectIds: newSelections });
  };

  const handlePropertyTypeChange = (newSelection) => {
    let newSubSelectIds = [];
    if (newSelection === '1') {
      newSubSelectIds = ['1', '2'];
    } else if (newSelection === '2') {
      newSubSelectIds = ['4'];
    }

    setModalFilters({
      ...modalFilters,
      propertySubSelectIds: newSubSelectIds,
      propertyTypeId: newSelection
    });
  };

  const handlePriceChange = ({ min, max }) => {
    setModalFilters({ ...modalFilters, maxPrice: max, minPrice: min });
  };

  const handleClickToResultsPage = () => {
    pushStateToQuery(router, { ...modalFilters, status: ['Active', 'Pending'] });
    setModalIsOpen(false);
  };

  const propertyTypesWithoutAny = propertyTypeMap.slice(1);

  return (
    <div style={{ width: '100%' }}>
      <div className={subheader_container}>
        <span className={subheader}>Location</span>
        <button onClick={() => setCurrentStep(1)}>Add Location</button>
      </div>
      {modalFilters?.locations?.length > 0 && (
        <div className={locations_container}>
          {modalFilters.locations.map((loc) => (
            <div className={location_label} key={`${loc.label}-tag`}>
              {loc.label}
            </div>
          ))}
        </div>
      )}
      <span className={subheader}>Details</span>
      <div style={{ marginBottom: '30px' }}>
        <RadioButtons
          label="Property Type"
          groupName="propertyTypeId"
          items={propertyTypesWithoutAny}
          selection={modalFilters.propertyTypeId}
          subSelections={modalFilters.propertySubSelectIds}
          onSubChange={handlePropertySubChange}
          onChange={handlePropertyTypeChange}
        />
      </div>
      <div style={{ marginBottom: '30px' }}>
        <MultipleSelect
          anyLabel="Any Price"
          label="Price"
          id="price"
          options={priceOptions}
          defaultMin={defaultModalFilters.minPrice}
          defaultMax={defaultModalFilters.maxPrice}
          min={parseInt(modalFilters.minPrice)}
          max={parseInt(modalFilters.maxPrice)}
          onChangeMinMax={handlePriceChange}
        />
      </div>
      <div style={{ marginBottom: '30px' }} className={button_container}>
        <Button
          label={`View ${count ? count : ''} properties`}
          onClick={handleClickToResultsPage}
        ></Button>
      </div>
    </div>
  );
};

StepTwo.propTypes = {
  /**
   * default modalFilters that hold the original state before any changes have been made
   * within the modal itself. Locations default to redux state if it's not a new search modal
   * Store won't get modified until changes are submitted.
   */
  defaultModalFilters: PropTypes.shape({
    locations: PropTypes.array,
    maxPrice: PropTypes.number,
    minPrice: PropTypes.number,
    propertySubSelectIds: PropTypes.array,
    propertyTypeId: PropTypes.string
  }),

  /**
   * modalFilters that keep state local so that redux doesn't get updated before apply
   */
  modalFilters: PropTypes.shape({
    locations: PropTypes.array,
    maxPrice: PropTypes.number,
    minPrice: PropTypes.number,
    propertySubSelectIds: PropTypes.array,
    propertyTypeId: PropTypes.string
  }),

  /**
   * Updates which step of the advanced search is currently opened
   */
  setCurrentStep: PropTypes.func,

  /**
   *  Updates the current local modal selections
   */
  setModalFilters: PropTypes.func,

  /**
   * Sets whether the modal is open or closed
   */
  setModalIsOpen: PropTypes.func
};

export default StepTwo;
