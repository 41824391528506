import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactModal from 'react-modal';

// ---------------------------------------------------------

import styles from './styles.module.scss';
const {
  close_button,
  modal,
  modal_advanced_search,
  modal_inner,
  modal_overlay,
  modal_alert,
  modal_notes,
  modal_property_images,
  modal_body_class,
  modal_pre_approval
} = styles;

// ---------------------------------------------------------

import Icon from '@components/icon';

// ---------------------------------------------------------

const Modal = (props) => {
  const { children, content, contentLabel, onModalClose, modalIsOpen, theme } = props;

  ReactModal.setAppElement('body');

  // ---------------------------------------------------------

  const overflowHidden = (modalIsOpen) => {
    if (modalIsOpen) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.removeProperty('overflow');
    }
  };

  useEffect((modalIsOpen) => {
    overflowHidden(modalIsOpen);
  }, []);

  // ---------------------------------------------------------

  const classes = classNames(modal, {
    [modal_advanced_search]: theme === 'advanced-search',
    [modal_alert]: theme === 'alert',
    [modal_notes]: theme === 'notes',
    [modal_property_images]: theme === 'property-images',
    [modal_pre_approval]: theme === 'pre-approval'
  });

  // ---------------------------------------------------------

  return (
    <ReactModal
      isOpen={modalIsOpen}
      overlayClassName={modal_overlay}
      contentLabel={contentLabel}
      className={classes}
      onRequestClose={onModalClose}
      shouldReturnFocusAfterClose={false}
      bodyOpenClassName={modal_body_class}
      preventScroll={true}
    >
      <div
        className={modal_inner}
        style={
          ['alert', 'notes'].includes(theme)
            ? {}
            : { backgroundImage: `url("/images/modal-background.png")` }
        }
      >
        {content || children}
        <button className={close_button} onClick={onModalClose}>
          <Icon type="actions" name="close" />
        </button>
      </div>
    </ReactModal>
  );
};

Modal.propTypes = {
  /**
   * Specifies the modal button
   */
  button: PropTypes.node,

  /**
   * Specifies the modal content
   */
  children: PropTypes.node,
  /**
   * Specifies the modal content
   */
  content: PropTypes.node,

  /**
   * Specifies the content label on the modal.
   */
  contentLabel: PropTypes.string,

  /**
   * Specifies whether the modal is currently open
   */
  modalIsOpen: PropTypes.bool,

  /**
   * Function that will be run after the modal has closed.
   */
  onModalClose: PropTypes.func,

  /**
   * Specifies the theme of the modal
   */
  theme: PropTypes.string
};

export default Modal;
